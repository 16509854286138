//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GeneralTable from '@/components/GeneralTable';
import { log } from 'util';
import checkPermission from '@/utils/permission'; // 权限判断函数

export default {
  name: 'Index',
  components: {
    GeneralTable: GeneralTable
  },
  data: function data() {
    return {
      moduleName: 'organization_module',
      tableName: 't_organization',
      url: {
        getUrl: '/organization',
        method: 'post',
        query: {}
      },
      canSearch: checkPermission('master.organization.list'),
      //true,
      canAdd: checkPermission('master.organization.add'),
      //true,
      canEdit: checkPermission('master.organization.edit'),
      //true,
      canDelete: checkPermission('master.organization.delete'),
      //true,
      canBatchEdit: checkPermission('master.organization.batch'),
      //true,
      canDownload: checkPermission('master.organization.download'),
      //true
      canImport: checkPermission('master.organization.import_data')
    };
  },
  created: function created() {},
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    // 保存离开本页面时的查询条件，当再次进入本页面时回显
    if (this.$refs.GeneralTable) {
      sessionStorage.setItem(window.location.href + '_LIST_QUERY', JSON.stringify(this.$refs.GeneralTable.getListQuery()));
    }
    next();
  },
  methods: {}
};